import React from "react";
import Lottie from "lottie-react";
import ApiLoading from "../lottie/loader1.json";

export const LottieLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie animationData={ApiLoading} loop={true} />
    </div>
  );
};
