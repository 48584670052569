import * as Realm from "realm-web"
import envConfig from "../../env/env.json"
export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID })
export const gApp = new Realm.App({ id: envConfig.MONGODB_APP_ID_GENERAL })

export const {
  BSON: { ObjectId },
} = Realm

export const getDBInstance = appInstance => {
  return appInstance.currentUser
    .mongoClient(envConfig.MONGODB_CLIENT)
    .db(envConfig.MONGODB_DB)
}

export const generalLogin = async () => {
  const gUser = await gApp.logIn(
    Realm.Credentials.apiKey(envConfig.MONGODB_APP_TOKEN_GENERAL)
  )
  return gUser
}

export const getAllCustomer = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("customers")
    .find({}, queryOptions)

  return result
}

export const getAllProvider = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .find({}, queryOptions)

  return result
}

export const getAllCategory = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .find({}, queryOptions)

  return result
}

export const getAllCity = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("cities")
    .find({}, queryOptions)

  return result
}

export const getAllOrders = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      // {
      //   $match: {},
      // },

      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $lookup: {
          from: "providers",
          localField: "providerId",
          foreignField: "_id",
          as: "provider",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $unwind: "$provider",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
      // {
      //   $limit: 10,
      // },
    ])

  return result
}

export const AddOneCategory = async ({
  data,
  checkedShowInApp,
  checkedActive,
  categoryType,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data || !categoryType) {
    return
  }

  const metadata = {
    createdBy: null,
    isActive: checkedActive ? checkedActive : false,
    createdAt: new Date(),
    modifiedBy: null,
    modifiedAt: null,
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .insertOne({
      metadata: metadata,
      name: {
        // ar: data.arabicName,
        en: data.englishName,
      },
      showInApp: checkedShowInApp ? checkedShowInApp : false,
      type: categoryType,
      mainImage: null,
    })
  return result
}

export const UpdateOneCategory = async ({
  item,
  data,
  checkedShowInApp,
  checkedActive,
  categoryType,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data) {
    return
  }
  // console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("categories")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": checkedActive,
          "metadata.modifiedAt": new Date(),
          name: {
            // ar: data.arabicName,
            en: data.englishName,
          },
          showInApp: checkedShowInApp,
          type: categoryType,
          mainImage: null,
        },
      }
    )

  return result
}

export const DeleteCategory = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .deleteOne({ _id: itemId })

  return result
}

export const UpdateOneCustomer = async ({
  item,
  data,
  profileImg,
  checkedActive,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("customers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": checkedActive,
          "metadata.modifiedAt": new Date(),
          firstName: data.firstName,
          lastName: data.lastName,
          mainImage: profileImg,
        },
      }
    )

  return result
}

export const DeleteCustomer = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("customers")
    .deleteOne({ _id: itemId })

  return result
}

export const AddOneCity = async ({ data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data) {
    return
  }

  const metadata = {
    createdBy: null,
    isActive: true,
    createdAt: new Date(),
    modifiedBy: null,
    modifiedAt: null,
  }

  const result = await getDBInstance(app).collection("cities").insertOne({
    metadata: metadata,
    name: data.name,
  })
  return result
}

export const UpdateOneCity = async ({ item, data }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("cities")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.modifiedAt": new Date(),
          name: data.name,
        },
      }
    )

  return result
}

export const DeleteCity = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("cities")
    .deleteOne({ _id: itemId })

  return result
}

export const AddOneProvider = async ({ data, type, resIcon, resBanner }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data) {
    return
  }

  // Prepare the updated data object
  const updatedData = {
    ...data,
    type: type || "home-business", // Use provided type or default to "home-business"
    arabicName: data.arabicName || data.englishName, // Use englishName if arabicName is undefined
    arabicBio: data.arabicBio || data.englishBio, // Use englishBio if arabicBio is undefined
  }

  // Call the function to register the provider with updated data
  const result = await app.currentUser.callFunction("registerProvider", {
    data: updatedData, // Pass the updated data
    resIcon,
    resBanner,
  })

  return result
}

export const UpdateOneProvider = async ({ item, data, resIcon, resBanner }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("providers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.modifiedAt": new Date(),
          number: data.number,
          whatsapp: data.whatsapp,
          instagram: data.instagram,
          facebook: data.facebook,
          description: {
            en: data.englishBio,
            // ar: data.arabicBio,
          },
          name: {
            en: data.englishName,
            // ar: data.arabicName,
          },
          icon: resIcon,
          mainImage: resBanner,
        },
      }
    )

  return result
}

export const DeleteOneProvider = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .deleteOne({ _id: itemId })

  const branchResult = await getDBInstance(app)
    .collection("branches")
    .deleteMany({ providerId: itemId })

  return { result, branchResult }
}
//isApproved
export const getNotApprovedProviders = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .find({ isApproved: false }, queryOptions)

  return result
}

export const getNotApprovedBranches = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("branches")
    // .find({ isApproved: false }, queryOptions);
    .aggregate([
      {
        $match: { isApproved: false },
      },

      {
        $lookup: {
          from: "providers",
          localField: "providerId",
          foreignField: "_id",
          as: "provider",
        },
      },

      {
        $unwind: "$provider",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getNotApprovedMenus = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("menus")
    // .find({ isApproved: false }, queryOptions);
    .aggregate([
      {
        $match: { isApproved: false },
      },

      {
        $lookup: {
          from: "providers",
          localField: "providerId",
          foreignField: "_id",
          as: "provider",
        },
      },

      {
        $unwind: "$provider",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getNotApprovedAddons = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    // .find({ isApproved: false }, queryOptions);
    .aggregate([
      {
        $match: { isApproved: false },
      },

      {
        $lookup: {
          from: "providers",
          localField: "providerId",
          foreignField: "_id",
          as: "provider",
        },
      },

      {
        $unwind: "$provider",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getNotActiveReview = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("reviews")
    // .find({ isApproved: false }, queryOptions);
    .aggregate([
      {
        $match: { "metadata.isActive": false },
      },

      {
        $lookup: {
          from: "customers",
          localField: new ObjectId("reviewedBy.entityId"),
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $lookup: {
          from: "providers",
          localField: new ObjectId("reviewedOn.entityId"),
          foreignField: "_id",
          as: "provider",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $unwind: "$provider",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const UpdateProviderApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("providers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

export const UpdateBranchApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("branches")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

export const UpdateMenuApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("menus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

export const UpdateAddonApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("modifiers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

//Revenue
// export const getProvidersSearch = async () => {
//   if (!app || !app.currentUser) {
//     return
//   }

//   const result = await getDBInstance(app)
//     .collection("providers")
//     .aggregate([
//       {
//         $match: {
//           isApproved: true,
//         },
//       },
//       {
//         $replaceRoot: {
//           newRoot: {
//             id: "$_id",
//             label: {
//               $concat: ["$name.en", "(", "$name.ar", ")"],
//             },
//           },
//         },
//       },
//     ])
//   return result
// }

export const cancelOrder = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  const gUser = await generalLogin()

  return await gUser?.callFunction("orders", {
    functionName: "cancelOrder",
    params: { id },
  })
}

export const acceptOrder = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  const gUser = await generalLogin()

  return await gUser?.callFunction("orders", {
    functionName: "acceptOrder",
    params: { id },
  })
}

export const OrderStatusUpdate = async ({ item, orderStatus }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !orderStatus) {
    return
  }

  const gUser = await generalLogin()

  const params = {
    _id: item._id.toString(),
    orderStatus: orderStatus,
  }

  const result = await gUser?.callFunction("orders", {
    functionName: "orderStatusChange",
    params: params,
  })

  return result
  //console.log("orderStatus::", orderStatus);

  // const result = await getDBInstance(app)
  //   .collection("orders")
  //   .updateOne(
  //     {
  //       _id: item._id,
  //     },
  //     {
  //       $set: {
  //         status: orderStatus,
  //       },
  //     }
  //   );

  return result
}

export const getAllPages = async () => {
  if (!app || !app.currentUser) {
    return
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("pages")
    .find({}, queryOptions)

  return result
}

export const getProviderSearch = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .aggregate([
      {
        $match: {},
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en"],
            // },
          },
        },
      },
    ])
  console.log(":", typeof result)
  return result
}

export const getOrdersBtwDates = async ({ startDate, endDate, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [{ "metadata.isActive": true }, { status: "completed" }],
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (providerId) {
    query.$and.push({ providerId: providerId })
  }
  if (startDate && endDate) {
    query.$and.push({
      "metadata.createdAt": { $gt: startDate },
    })
    query.$and.push({
      "metadata.createdAt": { $lt: endDate },
    })
  }

  // console.log("query:", query);

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisyear = async ({
  startOfYear,
  endOfYear,
  providerId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfYear, endOfYear);
  if (!startOfYear || !endOfYear) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfYear },
      },
      {
        "metadata.createdAt": { $lt: endOfYear },
      },
      { "metadata.isActive": true },
      { status: "completed" },
    ],
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (providerId) {
    query.$and.push({ providerId: providerId })
  }
  // if (typeof providerId === "string") {
  //   providerId = new ObjectId(providerId);
  // }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisMonth = async ({
  startOfMonth,
  endOfMonth,
  providerId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfMonth, endOfMonth);
  if (!startOfMonth || !endOfMonth) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfMonth },
      },
      {
        "metadata.createdAt": { $lt: endOfMonth },
      },
      { "metadata.isActive": true },
      { status: "completed" },
    ],
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (providerId) {
    query.$and.push({ providerId: providerId })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisWeek = async ({
  startOfWeek,
  endOfWeek,
  providerId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfWeek, endOfWeek);
  if (!startOfWeek || !endOfWeek) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfWeek },
      },
      {
        "metadata.createdAt": { $lt: endOfWeek },
      },
      { "metadata.isActive": true },
      { status: "completed" },
    ],
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (providerId) {
    query.$and.push({ providerId: providerId })
  }
  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisDay = async ({
  startTodays,
  endTodays,
  providerId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startTodays, endTodays);
  if (!startTodays || !endTodays) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startTodays },
      },
      {
        "metadata.createdAt": { $lt: endTodays },
      },
      { "metadata.isActive": true },
      { status: "completed" },
    ],
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (providerId) {
    query.$and.push({ providerId: providerId })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}
